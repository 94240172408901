import axios from 'axios'
import React, { Suspense, useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import store from './store'
import history from './utils/history'
import Page404 from './views/pages/page404/Page404'
import { ToastContainer } from 'react-toastify'

const API_URL = 'https://vunon.com.tr:8443/api/'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const Home = React.lazy(() => import('./views/home/Home'))

// Pages
const HomePage = React.lazy(() => import('./views/pages/HomePage/HomePage'))
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const MultiStep = React.lazy(() => import('./views/Customers/MultiStep/MultiStep'))
const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const Foundation = React.lazy(() => import('./views/foundation/Foundation'))
const Business = React.lazy(() => import('./views/Business/Business'))
const UserManagement = React.lazy(() => import('./views/UserManagement/UserManagement'))
/* const AllReservations = React.lazy(() => import('./views/AllReservations/allReservations'))
 */ const ViewBusiness = React.lazy(() => import('./views/Business/ViewBusiness'))
const PartsBusiness = React.lazy(() => import('./views/Business/PartsBusiness'))
const PackageBusiness = React.lazy(() => import('./views/Business/PackageBusiness'))
const EditBusiness = React.lazy(() => import('./views/Business/EditBusiness'))
const EditPartsBusiness = React.lazy(() => import('./views/Business/EditPartsBusiness'))
const ViewFoundation = React.lazy(() => import('./views/foundation/ViewFoundation'))
const EditFoundation = React.lazy(() => import('./views/foundation/EditFoundation'))
const AddBusiness = React.lazy(() => import('./views/Business/AddBusiness'))
const AddPartsBusiness = React.lazy(() => import('./views/Business/AddPartsBusiness'))
const AddPackageBusiness = React.lazy(() => import('./views/Business/AddPackageBusiness'))
const EditPackageBusiness = React.lazy(() => import('./views/Business/EditPackageBusiness'))
const Reservations = React.lazy(() => import('./views/reservations/Reservations'))
const ReservationCreate = React.lazy(() => import('./views/reservations/ReservationCreate'))
const ViewReservation = React.lazy(() => import('./views/reservations/ViewReservation'))
const AddUser = React.lazy(() => import('./views/UserManagement/AddUser'))
const EditUser = React.lazy(() => import('./views/UserManagement/EditUser'))

const CompanyHome = React.lazy(() => import('./react-company/pages/CompanyHome'))
const Blog = React.lazy(() => import('./react-company/pages/Blog'))
const BlogDetails = React.lazy(() => import('./react-company/pages/BlogDetails'))
const About = React.lazy(() => import('./react-company/pages/About'))
const Contact = React.lazy(() => import('./react-company/pages/Contact'))

const App = () => {
  const URL = window.location.pathname
  const [foundationName, setFoundationName] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const isUserLoggedIn = localStorage.getItem('user') !== null

  const loadFoundationName = async () => {
    const restaurantNameUrl = URL.split('/')
    await axios
      .get(API_URL + `restaurant/` + restaurantNameUrl[1])
      .then((response) => {
        setFoundationName(response.data)
      })
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    loadFoundationName()
  }, [])

  useEffect(() => {
    // 2 saniyelik gecikme
    const timeout = setTimeout(() => {
      setIsLoading(false)
    }, 2000)

    // Cleanup timeout
    return () => clearTimeout(timeout)
  }, [])

  if (isLoading && foundationName?.name) {
    // Yükleniyor mesajı sadece MultiStep rotası için
    return <div className="loading-container">Yükleniyor...</div>
  }

  return (
    <Provider store={store}>
      <Suspense fallback={loading}>
        <Routes history={history}>
          <Route path="/" name="Home" element={<Home />}>
            <Route
              path="/dashboard"
              element={isUserLoggedIn ? <Dashboard /> : <Navigate to="/tr" />}
            />

            <Route path="/foundation" name="Foundation" element={<Foundation />} />
            <Route path="/viewfoundation/:id" name="Foundation" element={<ViewFoundation />} />
            <Route path="/editfoundation/:id" name="Foundation" element={<EditFoundation />} />

            <Route path="/addbusiness" name="Add Business" element={<AddBusiness />} />
            <Route path="/business" name="Business" element={<Business />} />
            <Route path="/viewbusiness/:name" name="ViewBusiness" element={<ViewBusiness />} />
            <Route
              path="/partsbusiness/:name/:id"
              name="PartsBusiness"
              element={<PartsBusiness />}
            />
            <Route
              path="/addpartsbusiness/:id"
              name="AddPartsBusiness"
              element={<AddPartsBusiness />}
            />
            <Route
              path="/addpackagebusiness/:id"
              name="Business"
              element={<AddPackageBusiness />}
            />
            <Route
              path="/packagebusiness/:name/:id"
              name="Business"
              element={<PackageBusiness />}
            />
            <Route path="/editbusiness/:id" name="EditBusiness" element={<EditBusiness />} />
            <Route
              path="/editpartsbusiness/:name/:id/part/:partid"
              name="EditPartsBusiness"
              element={<EditPartsBusiness />}
            />
            <Route
              path="/editpackagebusiness/:name/:id/package/:packageid"
              name="EditPackageBusiness"
              element={<EditPackageBusiness />}
            />

            <Route path="/reservations/:id" name="Reservations" element={<Reservations />} />
            <Route
              path="/addreservation/:id"
              name="Reservations Create"
              element={<ReservationCreate />}
            />
            <Route
              path="/viewreservation/:restaurantName/:id"
              name="View Reservation"
              element={<ViewReservation />}
            />

            <Route path="/user-management" name="User Management" element={<UserManagement />} />
            {/*             <Route path="/all-reservations" name="All Reservations" element={<AllReservations />} />
             */}
            <Route path="/adduser" name="Add User" element={<AddUser />} />
            <Route path="/edituser/:id" name="Edit User" element={<EditUser />} />
          </Route>
          <Route path="/homepage" name="Home Page" element={<HomePage />} />
          <Route path="/blog" name="Blog Page" element={<Blog />} />
          <Route path="/blog/:slug" name="Blog Details Page" element={<BlogDetails />} />
          <Route path="/tr" name="Company Page" element={<CompanyHome />} />
          <Route path="/about" name="About Page" element={<About />} />
          <Route path="/contact" name="Contact Page" element={<Contact />} />
          <Route path="/login" name="Login Page" element={<Login />} />
          <Route path="/register" name="Register Page" element={<Register />} />
          {foundationName?.name && (
            <Route
              path={`/${foundationName?.name}`}
              name="Multi Step Page"
              element={<MultiStep />}
            />
          )}{' '}
          <Route path="*" element={<Page404 />} />
        </Routes>
        <ToastContainer />
      </Suspense>
    </Provider>
  )
}

export default App
